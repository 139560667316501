import auth from 'app/auth/store/reducers';
import recommendDialogSlice from 'app/fuse-layouts/shared-components/recommend/store/recommendDialogSlice';
import orderDevicesSlice from 'app/main/apps/devices/store/orderDevicesSlice';
import rvmSlice from 'app/main/apps/devices/store/rvmSlice';
import businessCardSlice from 'app/main/apps/partner/store/businessCardSlice';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { i18nReducer } from 'react-redux-i18n';
import { Reducer, combineReducers } from 'redux';

import loginSlice from '../../auth/store/LoginSlice';
import userSlice from '../../auth/store/UserSlice';
import channelsNew from '../../main/apps/channels/store/reducers/channels.reducer';
import predefinedMsg from '../../main/apps/communication/predefinedMessages/store/reducers/predefinedMessages.reducer';
import accessReducer from '../../main/apps/dashboardAccess/store/reducers/index';
import devicesSlice from '../../main/apps/devices/store/DevicesSlice';
import devices from '../../main/apps/devices/store/reducers/index';
import jobs from '../../main/apps/jobTitles/store/reducers/jobtitle.reducer';
import partnerSlice from '../../main/apps/partner/store/PartnerSlice';
import partnerReducer from '../../main/apps/partner/store/reducers/index';
import personnelReducer from '../../main/apps/personnel/store/reducers/personnel.reducer';
import personnelOnline from '../../main/apps/personnelOnline/store/reducers/personnelOnline.reducer';
import rmaSlice from '../../main/apps/rma/store/rmaSlice';
import productsSlice from '../slices/productsSlice';
import common from './common';
import objectsSlice from './common/objectsSlice';
import forSelect from './forSelect';
import fuse from './fuse';
import shadowService from './shadowService';

interface AsyncReducers {
  [key: string]: Reducer;
}

const createReducer = (asyncReducers: AsyncReducers) =>
  combineReducers({
    auth,
    fuse,
    common,
    forSelect,
    shadowService,
    ...asyncReducers,
    i18n: i18nReducer,
    predefinedMsg,
    personnelReducer,
    jobs,
    channelsNew,
    personnelOnline,
    access: accessReducer,
    partnerReducer,
    devices,
    rma: rmaSlice,
    objects: objectsSlice,
    partner: partnerSlice,
    // CWAT-4089
    // facilityDetails: facilityDetailsSlice,
    businessCard: businessCardSlice,
    products: productsSlice,
    login: loginSlice,
    user: userSlice,
    devicesSlice: devicesSlice,
    recommendDialog: recommendDialogSlice,
    orderDevices: orderDevicesSlice,
    rvm: rvmSlice
  });

export default createReducer;

type RootState = ReturnType<ReturnType<typeof createReducer>>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
