import { useSelector } from 'react-redux';

import { ROLE_SERVICE, ROLE_VIEW_ONLY } from '../../lib/constants';

export function useRoles() {
  const { currentRole, partner, juniorPartnerAccess } = useSelector(({ auth }) => auth.user);

  const isPartner = Boolean(partner);
  const isService = currentRole === ROLE_SERVICE;

  return {
    hasPermissionToCreateEditRemove: currentRole !== ROLE_VIEW_ONLY,
    isManager: !(isPartner || isService),
    isPartner,
    isService,
    isJuniorPartner: juniorPartnerAccess && !isService
  };
}
