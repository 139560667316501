import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDialogOpen: false
};

const orderDevicesSlice = createSlice({
  name: 'orderDevices',
  initialState,
  reducers: {
    openOrderDevicesDialog: state => {
      state.isDialogOpen = true;
    },
    closeOrderDevicesDialog: state => {
      state.isDialogOpen = false;
    }
  }
});

export const { openOrderDevicesDialog, closeOrderDevicesDialog } = orderDevicesSlice.actions;

export default orderDevicesSlice.reducer;
